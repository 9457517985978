import React, { useRef, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DesignersNav from '../Nav/DesignersNav';
import { isMobile } from 'react-device-detect';
import Followrs from './Followrs';
import Models from './Models';
import Reviews from './Reviews';
import Comments from './Comments';
import MySales from './MySales';
import Dropdown from '../Dropdown';
import UserContext from '../../../context/user/user';
import { useRecoilState } from 'recoil';
import {
  designerPaginationAtom,
  selectedTabAtom,
  selectedSortAtom,
  seletecdMoreAtom,
  seletecdYearAtom,
  commentPrevAtom,
  managePrevAtom,
  editModelAtom,
  itemDetailNavAtom,
} from '../../../atom/atom';
import { Contents, DropDownContainer } from '../designers';
import moment from 'moment';
import ManageModels from './manageModel/ManageModels';
import { useNavigate } from 'react-router-dom';

const DesignersContents = ({ designerURL, designerId, state }) => {
  const scrollRef = useRef(null);

  const initalValue = { title: 'Latest', value: 'Latest' };
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabAtom);
  const [selectedSort, setSeletedSort] = useRecoilState(selectedSortAtom);
  const [, setPageNation] = useRecoilState(designerPaginationAtom);
  const { state: userState } = useContext(UserContext);
  const [, setSelectedMore] = useRecoilState(seletecdMoreAtom);
  const [, setPrevComment] = useRecoilState(commentPrevAtom);
  const [, setPrevState] = useRecoilState(managePrevAtom);

  const [editModel] = useRecoilState(editModelAtom);
  const [, setSelectYear] = useRecoilState(seletecdYearAtom);
  const [isApp, setIsApp] = useState(true);
  const scrollD = localStorage.getItem('scrollDesigner');
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const selectedTabIDValue = queryParams.get('selectedTabID');
  const isDesignerCheck = Number(userState?.idStudio) === Number(designerURL);

  const ModelsSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Most Downloaded', value: 'Downloads' },
    { title: 'Most Commented', value: 'Comment' },
    { title: 'Most Liked ', value: 'Like' },
  ];

  const FollowerSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Top Downloaders', value: 'Download' },
  ];

  const ReviewSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Most Commented', value: 'Comment' },
    { title: 'Most Liked', value: 'Like' },
  ];

  const sortNone =
    Number(selectedTab) === 2 ||
    Number(selectedTab) === 4 ||
    Number(selectedTab) === 5 ||
    Number(selectedTabIDValue) === 5;

  useEffect(() => {
    if (selectedTabIDValue) {
      const tabID = Number(selectedTabIDValue);
      const userStudioID = Number(userState?.idStudio);
      const isRestrictedTab = (tabID === 5 || tabID === 4) && Number(designerURL) !== userStudioID;

      if (isRestrictedTab) {
        alert('Sorry, you are not allowed to access this page.');
        return navigate('/');
      }

      setSelectedTab(tabID);
    } else {
      setIsApp(false);
    }

    if (state?.action !== 'viewDetails') {
      moveScroll();
    }
  }, [selectedTab, selectedSort]);

  useEffect(() => {
    if (editModel?.status) {
      setSelectedTab(4);
      return;
    }
    if (
      (selectedTab === 5 || selectedTab === 4 || selectedTab === 6) &&
      userState?.idStudio !== designerURL
    ) {
      setSelectedTab(0);
    }
  }, [designerURL, userState]);

  const moveScroll = () => {
    const moveTo = isMobile ? 120 : 110;
    if (!isApp)
      setTimeout(() => window.scrollTo({ top: scrollRef?.current?.offsetTop - moveTo }), 100);
  };

  const onClickTab = nav => {
    setSelectedTab(nav.value);
    setPageNation(1);
    setPrevComment('null');
    setSeletedSort(initalValue);
    setPrevState(false);

    if (selectedTab !== 5) {
      setSelectedMore('my');
    }

    setSelectYear(moment().format('YYYY'));
    if (nav?.value !== 3) {
      moveScroll();
    }
  };

  function sortData(tab) {
    if (tab === 0) {
      return ModelsSortOption;
    } else if (tab === 1) {
      return FollowerSortOption;
    } else if (tab === 3) {
      return ReviewSortOption;
    }
  }

  const onChangeSort = sort => {
    setSeletedSort(sort);
    setPageNation(1);
  };

  useEffect(() => {
    if (editModel?.status) {
      setTimeout(() => {
        window.scrollTo(0, 360);
      }, 500);
    }
  }, [scrollD]);

  return (
    <div>
      {!isApp ? (
        <div ref={scrollRef} id='nav' className='designerNav'>
          <DesignersNav
            selectedTab={selectedTab}
            onClickTab={onClickTab}
            userState={userState}
            designerURL={designerURL}
          />
        </div>
      ) : (
        <div ref={scrollRef}></div>
      )}

      {sortNone ? (
        ''
      ) : (
        <DropDownContainer>
          <Dropdown
            options={sortData(selectedTab)}
            onChangeSort={onChangeSort}
            selectedSort={selectedSort}
          />
        </DropDownContainer>
      )}

      <Contents className='container'>
        {selectedTab === 0 ? (
          <Models
            moveScroll={moveScroll}
            selectedSort={selectedSort}
            designerURL={designerURL}
            selectedTab={selectedTab}
          />
        ) : selectedTab === 1 ? (
          <Followrs selectedSort={selectedSort} moveScroll={moveScroll} data={designerURL} />
        ) : selectedTab === 2 ? (
          <Comments
            userState={userState}
            designerURL={designerURL}
            designerId={designerId}
            scrollRef={scrollRef}
            isMobile={isMobile}
            moveScroll={moveScroll}
          />
        ) : selectedTab === 3 ? (
          <Reviews
            moveScroll={moveScroll}
            value='review'
            selectedSort={selectedSort}
            designerURL={designerURL}
          />
        ) : selectedTab === 4 ? (
          isDesignerCheck && <ManageModels designerURL={designerURL} moveScroll={moveScroll} />
        ) : (
          isDesignerCheck && <MySales moveScroll={moveScroll} />
        )}
      </Contents>
    </div>
  );
};

export default DesignersContents;
