/* eslint-disable no-unused-vars */
import { createBrowserRouter } from 'react-router-dom';
import About from '../page/About/About';
import AboutCeo from '../page/About/AboutCeo';
import AboutCoCeo from '../page/About/AboutCoCeo';
import Philosophy from '../page/About/Philosophy';
import Service from '../page/About/Service';
import Business from '../page/Contact/Business';
import Contact from '../page/Contact/Contact';
import Copyright from '../page/Copyright/Copyright';
import BedPei from '../page/PrintTip/BedPei';
import BestBed from '../page/PrintTip/BestBed';
import PrintTip from '../page/PrintTip/PrintTip';
import BedType from '../page/PrintTip/BedType';
import Privacy from '../page/Privacy/Privacy';
import Term from '../page/Term/Term';
import {
  ABOUT,
  ABOUT_CEO,
  ABOUT_CO_CEO,
  ABOUT_PHILOSOPHY,
  ABOUT_SERVICE,
  APPLE_CALLBACK,
  GOOGLE_CALLBACK,
  YAHOO_CALLBACK,
  CATEGORIES,
  CHECKOUT_STEP1,
  CHECKOUT_STEP2,
  CHECKOUT_SUCCESS,
  CHECKOUT_FREE,
  COLLECTIONS,
  DESIGNERS,
  FOOTER_CONTACT,
  FOOTER_CONTACT_BUSINESS,
  FOOTER_COPYRIGHT,
  FOOTER_PRIVACY,
  FOOTER_TERMS,
  GITHUB_CALLBACK,
  HELP,
  HELP_HOW_TO_DOWNLOAD_MODEL_FILES,
  HELP_HOW_TO_DOWNLOAD_SLICER_SETTING,
  HELP_HOW_TO_IMPORT_FAB365_CURA_PROFILE,
  HELP_POINT_HOW_TO_USE,
  HELP_SLICER_SETTINGS,
  ITEMS,
  MY_CART,
  MY_DOWNLOADS,
  MY_REWARD_TICKETS,
  MY_LIKE,
  MY_ORDER,
  MY_CREDIT,
  MY_PROFILE,
  MY_REQUEST,
  MY_REQUEST_EDIT,
  MY_REQUEST_WRITE,
  MY_REVIEW,
  MY_REVIEW_EDIT,
  MY_REVIEW_WRITE,
  MY_SALES,
  MY_SALES_DAILY,
  MY_SALES_PRODUCT,
  MY_SALES_PRODUCTS,
  PAGEID_HELP_14013138301_BROKEN_LIVING_HINGE,
  PAGEID_HELP_14013138302_MOVING_PARTS_FALL,
  PAGEID_HELP_14013138303_PARTS_NOT_MOVE,
  PAGEID_HELP_14013138304_BED_TYPES,
  PAGEID_HELP_14013138305_BED_GOOD,
  PAGEID_HELP_14013138306_BED_PEI,
  PAGES_HELP_FREE_TICKET,
  PAGES_HELP_REVIEW,
  PRINT_TIPS_LIST,
  REQUESTS,
  REVIEW,
  SETITEMS,
  SNS_FACEBOOK,
  TWITTER_CALLBACK,
  FACEBOOK_CALLBACK,
  TERMS_POINT,
  RESET_PASSWORD,
  NOT_FOUND,
  SNS_SIGNUP,
  SNS_SIGNUP_PLANET,
  LANDING,
  INVITE,
  SEARCH,
  VERIFY,
  NOTICE,
  TEST,
  FOOTER_USER_LICENSE,
  APPLYDESIGNERFORM,
  APPFAB365LOGIN,
  APPDESIGNERS,
  APPMODELS,
  APPFOLLOWERS,
  APPCOMMENTS,
  APPREVIEWS,
  APPMANAGEMODELS,
  APPMYSALES,
  APPSETTINGNOTIFICATIONS,
  LOGINALLACCOUNTS,
} from './constant';
import PartsNotMove from '../page/PrintTip/PartsNotMove';
import MovingPartsFall from '../page/PrintTip/MovingPartsFall';
import BrokenLivingHinge from '../page/PrintTip/BrokenLivingHinge';
import Help from '../page/Help/Help';
import EarningPoints from '../page/Help/EarningPoints';
import FreeTicket from '../page/Help/FreeTicket';
import ImportProfile from '../page/Help/ImportProfile';
import DownloadModelFiles from '../page/Help/DownloadModelFiles';
import DownloadSlicerSetting from '../page/Help/DownloadSlicerSetting';
import SlicerFiles from '../page/Help/SlicerFiles';
import UsePoint from '../page/Help/UsePoint';

import Category from '../page/Category/Category';
import SetItem from '../page/Category/SetItem';
import Collection from '../page/Category/Collection';

import Request from '../page/Request/Request';
import RequestEdit from '../page/My/Request/RequestEdit';
import Review from '../page/Review/Review';
import Cart from '../page/Cart/Cart';
import Profile from '../page/My/Profile/Profile';
import Order from '../page/My/Order/Order';
import Like from '../page/My/Like/Like';
import Point from '../page/My/Point/Point';
import Download from '../page/My/Download/Download';
import MyRequest from '../page/My/Request/MyRequest';
import RequestCreate from '../page/My/Request/RequestCreate';
import MyReview from '../page/My/Review/MyReview';
import ReviewCreate from '../page/My/Review/ReviewCreate';
import ReviewEdit from '../page/My/Review/ReviewEdit';
import MyFreeTicket from '../page/My/MyFreeTicket/MyFreeTicket';
import Sales from '../page/My/Sales/Sales';
import SalesProduct from '../page/My/Sales/SalesProduct';
import SalesProductDetail from '../page/My/Sales/SalesProductDetail';
import SalesDaily from '../page/My/Sales/SalesDaily';
import Checkout from '../page/Checkout/Checkout';
import CheckoutConfirm from '../page/Checkout/CheckoutConfirm';
import AppleCallback from '../page/Sns/AppleCallback';
import TwitterCallback from '../page/Sns/TwitterCallback';
import GithubCallback from '../page/Sns/GithubCallback';
import GoogleCallback from '../page/Sns/GoogleCallback';
import YahooCallback from '../page/Sns/YahooCallback';
import FaceBookCallback from '../page/Sns/FacebookCallback';

import TermsPoint from '../page/My/TermsPoint/TermsPoint';
import CheckoutSucess from '../page/Checkout/CheckoutSucess';
import ResetPassword from '../page/ResetPassword';
import SnsSignup from '../page/Sns/SnsSignup';
import CheckoutFree from '../page/Checkout/CheckoutFree';
import NotFound from '../page/NotFound';
import Landing from '../page/InviteLanding/Landing';
import Invite from '../page/Invite/Invite';
import Search from '../page/Search';
import Verify from '../page/Verify/Verify';
import CollectionItemList from '../page/Category/component/CollectionItemList';
import Notice from '../page/Notice/Notice';
import Main2 from '../page/Main/Main2';
import Test from '../common/Test';
import OnlyDesigners from '../page/OnlyDesigners/OnlyDesigners';
import AppDesigners from '../page/OnlyDesigners/AppDesigners';
import UserLicense from '../page/License/UserLicense';
import NewDesigners from '../page/NewDesigners/NewDesigners';
import ItemDetail from '../page/Item/ItemDetail';
import Demo from '../component/editor/Demo';
import ApplyDesignerForm from '../page/ApplyForm/ApplyDesignerForm';
import AppdesignerLogin from '../page/Applogin/AppdesignerLogin';

import Followrs from '../component/designers/Contents/Followrs';
import Models from '../component/designers/Contents/Models';
import Reviews from '../component/designers/Contents/Reviews';
import Comments from '../component/designers/Contents/Comments';
import MySales from '../component/designers/Contents/MySales';
import MangeModels from '../component/designers/Contents/manageModel/ManageModels';
import AppDesignersContents from '../component/designers/Contents/AppDesignersContents';
import Signup from '../component/layout/Signup';
import NotificationToggle from '../component/designers/Contents/NotificationToggle';
import AllAccountsLogin from '../page/AllAccounts/AllAccountsLogin';

export const router = createBrowserRouter([
  {
    path: '/',

    element: <Main2 />,
  },
  {
    path: FOOTER_PRIVACY,
    element: <Privacy />,
  },
  {
    path: FOOTER_TERMS,
    element: <Term />,
  },
  { path: FOOTER_COPYRIGHT, element: <Copyright /> },
  { path: FOOTER_CONTACT, element: <Contact /> },
  { path: FOOTER_CONTACT_BUSINESS, element: <Business /> },
  { path: FOOTER_USER_LICENSE, element: <UserLicense /> },
  // { path: SNS_FACEBOOK},
  //About Section
  { path: ABOUT_SERVICE, element: <Service /> },
  { path: ABOUT, element: <About /> },
  { path: ABOUT_PHILOSOPHY, element: <Philosophy /> },
  { path: ABOUT_CEO, element: <AboutCeo /> },
  { path: ABOUT_CO_CEO, element: <AboutCoCeo /> },
  //Print Tip section
  {
    path: PRINT_TIPS_LIST,
    element: <PrintTip title={'Print Tips'} tab={'printTips'} />,
  },
  {
    path: PAGEID_HELP_14013138306_BED_PEI + '/:title?',
    element: <BedPei title={'Print Tips'} tab={'printTips'} />,
  },
  {
    path: PAGEID_HELP_14013138305_BED_GOOD + '/:title?',
    element: <BestBed title={'Print Tips'} tab={'printTips'} />,
  },
  {
    path: PAGEID_HELP_14013138304_BED_TYPES + '/:title?',
    element: <BedType title={'Print Tips'} tab={'printTips'} />,
  },
  {
    path: PAGEID_HELP_14013138301_BROKEN_LIVING_HINGE + '/:title?',
    element: <BrokenLivingHinge title={'Print Tips'} tab={'printTips'} />,
  },
  {
    path: PAGEID_HELP_14013138303_PARTS_NOT_MOVE + '/:title?',
    element: <PartsNotMove title={'Print Tips'} tab={'printTips'} />,
  },
  {
    path: PAGEID_HELP_14013138302_MOVING_PARTS_FALL + '/:title?',
    element: <MovingPartsFall title={'Print Tips'} tab={'printTips'} />,
  },

  //Help section
  {
    path: HELP,
    element: <Help title='Help' tab={'help'} />,
  },
  {
    path: PAGES_HELP_REVIEW,
    element: <EarningPoints />,
  },
  {
    path: PAGES_HELP_FREE_TICKET,
    element: <FreeTicket />,
  },
  {
    path: HELP_HOW_TO_IMPORT_FAB365_CURA_PROFILE + '/:title?',
    element: <ImportProfile title='Help' tab={'help'} />,
  },
  {
    path: HELP_HOW_TO_DOWNLOAD_MODEL_FILES + '/:title?',
    element: <DownloadModelFiles title='Help' tab={'help'} />,
  },
  {
    path: HELP_HOW_TO_DOWNLOAD_SLICER_SETTING + '/:title?',
    element: <DownloadSlicerSetting title='Help' tab={'help'} />,
  },
  {
    path: HELP_SLICER_SETTINGS,
    element: <SlicerFiles />,
  },
  {
    path: HELP_POINT_HOW_TO_USE + '/:title?',
    element: <UsePoint title='Help' tab={'help'} />,
  },

  // Categories

  {
    path: CATEGORIES + '/:category?',
    element: <Category />,
  },
  {
    path: SETITEMS,
    element: <SetItem />,
  },
  {
    path: COLLECTIONS,
    element: <Collection />,
  },
  {
    path: COLLECTIONS + '/:collectionURL',
    element: <CollectionItemList />,
  },
  {
    path: DESIGNERS,
    // element: <SpecialDesigners />,
    element: <NewDesigners />,
  },

  {
    path: DESIGNERS + '/:designerURL',
    element: <OnlyDesigners />,
  },
  // Item

  // App for maker
  // Detail list of models for maker
  {
    path: APPDESIGNERS + '/:designerURL' + APPMODELS,

    element: <AppDesignersContents tabId={0} />,
  },

  {
    path: APPDESIGNERS + APPMODELS,

    element: <AppDesignersContents tabId={0} />,
  },

  // Detail list of followers for maker
  {
    path: APPDESIGNERS + '/:designerURL' + APPFOLLOWERS,
    element: <AppDesignersContents tabId={1} />,
  },

  {
    path: APPDESIGNERS + APPFOLLOWERS,
    element: <AppDesignersContents tabId={1} />,
  },

  // Detail list of comments for maker
  {
    path: APPDESIGNERS + '/:designerURL' + APPCOMMENTS,
    element: <AppDesignersContents tabId={2} />,
  },

  {
    path: APPDESIGNERS + APPCOMMENTS,
    element: <AppDesignersContents tabId={2} />,
  },

  // Detail list of reviews for maker
  {
    path: APPDESIGNERS + '/:designerURL' + APPREVIEWS,
    element: <AppDesignersContents tabId={3} />,
  },

  {
    path: APPDESIGNERS + APPREVIEWS,
    element: <AppDesignersContents tabId={3} />,
  },

  // Detail list of manage models for maker
  {
    path: APPDESIGNERS + '/:designerURL' + APPMANAGEMODELS,
    element: <AppDesignersContents tabId={4} />,
  },

  {
    path: APPDESIGNERS + APPMANAGEMODELS,
    element: <AppDesignersContents tabId={4} />,
  },

  // Detail list of my sales for maker
  {
    path: APPDESIGNERS + '/:designerURL' + APPMYSALES,
    element: <AppDesignersContents tabId={5} />,
  },

  {
    path: APPDESIGNERS + APPMYSALES,
    element: <AppDesignersContents tabId={5} />,
  },

  {
    path: ITEMS + '/:id',
    element: <ItemDetail />,
  },

  //Request
  {
    path: REQUESTS + '/:oid?/:subject?',
    element: <Request />,
  },

  //Review

  {
    path: REVIEW + '/:oid?/:subject?',
    element: <Review />,
  },
  //MY
  { path: MY_PROFILE, element: <Profile /> },
  { path: MY_ORDER + '/:oid?', element: <Order /> },
  { path: MY_LIKE, element: <Like /> },
  { path: MY_CREDIT, element: <Point /> },
  { path: TERMS_POINT, element: <TermsPoint /> },
  { path: MY_DOWNLOADS, element: <Download /> },
  { path: MY_REQUEST, element: <MyRequest /> },
  { path: MY_REQUEST_WRITE, element: <RequestCreate /> },
  {
    path: MY_REQUEST_EDIT + '/:oid',
    element: <RequestEdit />,
  },
  { path: MY_REVIEW, element: <MyReview /> },
  { path: MY_REVIEW_WRITE + '/:idItem?', element: <ReviewCreate /> },
  { path: MY_REVIEW_EDIT + '/:oid', element: <ReviewEdit /> },
  { path: MY_REWARD_TICKETS, element: <MyFreeTicket /> },
  { path: MY_SALES, element: <Sales /> },
  { path: MY_SALES_PRODUCTS, element: <SalesProduct /> },
  { path: MY_SALES_PRODUCT + '/:pid', element: <SalesProductDetail /> },
  { path: MY_SALES_DAILY + '/:date', element: <SalesDaily /> },

  {
    path: MY_CART,
    element: <Cart />,
  },

  { path: CHECKOUT_STEP1, element: <Checkout /> },
  { path: CHECKOUT_STEP2, element: <CheckoutConfirm /> },
  { path: CHECKOUT_SUCCESS + '/:orderNumber', element: <CheckoutSucess /> },
  { path: CHECKOUT_FREE, element: <CheckoutFree /> },
  // { path: "*", element: <Main /> },

  // sns signup & login
  { path: APPLE_CALLBACK, element: <AppleCallback /> },
  { path: TWITTER_CALLBACK, element: <TwitterCallback /> },
  { path: GITHUB_CALLBACK, element: <GithubCallback /> },
  { path: GOOGLE_CALLBACK, element: <GoogleCallback /> },
  { path: YAHOO_CALLBACK, element: <YahooCallback /> },
  { path: FACEBOOK_CALLBACK, element: <FaceBookCallback /> },
  { path: SNS_SIGNUP, element: <SnsSignup /> },
  { path: SNS_SIGNUP_PLANET, element: <SnsSignup /> },
  { path: RESET_PASSWORD, element: <ResetPassword /> },

  // invite & landing
  { path: LANDING, element: <Landing /> },
  { path: INVITE, element: <Invite /> },

  // search
  { path: SEARCH, element: <Search /> },
  // verify
  { path: VERIFY, element: <Verify /> },
  // verify
  { path: NOTICE, element: <Notice /> },

  // not found > 코드 맨 밑에 있어야 함
  { path: NOT_FOUND, element: <NotFound /> },

  { path: APPLYDESIGNERFORM, element: <ApplyDesignerForm /> },
  { path: APPFAB365LOGIN, element: <AppdesignerLogin /> },

  { path: '/editor', element: <Demo /> },
  { path: '/index.html', element: <Main2 /> },
  { path: APPSETTINGNOTIFICATIONS, element: <NotificationToggle /> },
  { path: LOGINALLACCOUNTS, element: <AllAccountsLogin /> },
  //Request
]);
