import React from 'react';
import { useLocation } from 'react-router-dom';
import { HOSTNAME } from '../../../action/request';
import { SocialButton } from '../../layout/style/SignupStyle';
import { useRecoilValue } from 'recoil';
import { isMotilink } from '../../../atom/atom';

function Google() {
  const location = useLocation();
  const currentPath = location.pathname;
  const currentSearch = location.search;
  const AWS_URL = '43.203.118.174';
  const FAB365_URL = 'fab365.net';
  const FAB365_URL_MOCK = 'java.fab365.net';
  const FAB365_DEV_URL = 'dev.fab365.net';
  const FAB365_DEV_URL_MOCK = 'dev-java.fab365.net';
  const FAB365_DEV_URL_MOCK_SECOND = 'dev2.fab365.net';
  const isMotilinkValue = useRecoilValue(isMotilink);
  let redirectBaseUrl;

  if (HOSTNAME === FAB365_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL;
  } else if (HOSTNAME === FAB365_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK_SECOND) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK_SECOND;
  }

  // console.log(redirectBaseUrl);
  const config = {
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: redirectBaseUrl,
    access_type: 'offline',
    response_type: 'code',
    scope: 'email profile',
    ...(isMotilinkValue && { prompt: 'select_account' }),
  };

  const queryString = Object.entries(config)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const onClickGoogle = async e => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem(
      'loginPath',
      !currentSearch ? currentPath : `${currentPath}${currentSearch}`
    );

    const userAgent = navigator.userAgent;
    const isInstagram = userAgent.includes('Instagram');

    const targetUrl = 'https://fab365.net';

    if (isInstagram) {
      if (/android/i.test(userAgent)) {
        alert(
          `The platform's policies require login through Chrome, Safari, or Naver Whale.\nPlease open an external browser manually to continue. `
        );
        window.location.href = `intent://${targetUrl.replace(
          'https://',
          ''
        )}#Intent;scheme=https;package=com.android.chrome;end;`;
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        alert(
          `The platform's policies require login through Chrome, Safari, or Naver Whale.\nPlease tap the button below to continue in an external browser.`
        );
      }
      return;
    }

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;
  };

  // console.log('env', process.env.REACT_APP_TEST_ENV);

  return (
    <SocialButton className='btn btn-full social-button' onClick={onClickGoogle}>
      <div>
        <img src='/assets/img/login/google.png' alt='Google' />
        <p>Google</p>
      </div>
    </SocialButton>
  );
}

export default Google;
