import { useEffect, useState } from 'react';
import { parseDate } from '../../../../util/date';
import { round } from '../../../../util/number';
import moment from 'moment';
// import Pagination from '../../../Category/component/Pagination';
import Pagenation from '../../../../component/designers/Pagenation';
import { formatDesignerNumber } from '../utils';
import styled from 'styled-components';

const BalanceHistory = ({ data, isLoading }) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [histories, setHistories] = useState([]);
  const PAGE_COUNT = 50;

  function pageList() {
    let result = [];
    for (let i = 0; i < Math.min(data?.length, 0 + PAGE_COUNT); i++) {
      result.push(data[i]);
    }
    setHistories(result);
    setTotal(data?.length);
  }

  useEffect(() => {
    if (data?.length > 0) {
      pageList();
    } else {
      setHistories([]);
      setTotal(0);
    }
  }, [data]);

  const updatePage = async value => {
    setPage(value);

    let result = [];
    for (
      let i = PAGE_COUNT * (value - 1);
      i < Math.min(data?.length, PAGE_COUNT * (value - 1) + PAGE_COUNT);
      i++
    ) {
      result.push(data[i]);
    }
    setHistories(result);
  };

  const pageChange = async value => {
    updatePage(value);
  };

  const pageConfirm = value => {
    updatePage(value);
  };

  return (
    <History>
      <div className='fab-table-wrapper'>
        <div className='__scroll-area'>
          <table>
            <colgroup>
              <col style={{ width: '8em' }} />
              <col width='width:8em' />
              <col width='width:8em' />
              <col width='width:8em' />
              <col width='width:8em' />
              <col width='width:8em' />
              <col width='width:8em' />
              <col style={{ width: '10em' }} />
            </colgroup>
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Sold product</th>
                <th>Price</th>
                <th>Fee Rate</th>
                <th>Fees</th>
                <th>Incomes</th>
                <th>My Balance</th>
              </tr>
            </thead>
            <tbody id='history-rows'>
              {!isLoading &&
                histories &&
                histories?.map((h, idx) => {
                  if (h?.details === 'paid') {
                    return (
                      <tr key={`${h?.date} ${idx}`}>
                        <td>{moment(h?.date).format('YY.MM.DD')}</td>
                        <td>withdrawal</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span className='__fees'>
                            - $ {formatDesignerNumber(round(h?.amounts).toFixed(2))}
                          </span>
                        </td>
                        <td></td>
                        <td>
                          {h?.value
                            ? `- $ ${formatDesignerNumber(
                                round(Math.abs(h?.balance ?? 0)).toFixed(2)
                              )}`
                            : `$ ${formatDesignerNumber(round(h?.balance ?? 0).toFixed(2))}`}
                        </td>
                      </tr>
                    );
                  } else if (h?.details === 'request') {
                    return (
                      <tr key={`${h?.date} ${idx}`}>
                        <td>{moment(h?.date).format('YY.MM.DD')}</td>
                        <td>withdrawal in progress...</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span className='__fees'>
                            - $ {formatDesignerNumber(round(h?.amounts).toFixed(2))}
                          </span>
                        </td>
                        <td></td>
                        <td>
                          {h?.value
                            ? `- $ ${formatDesignerNumber(
                                round(Math.abs(h?.balance ?? 0)).toFixed(2)
                              )}`
                            : `$ ${formatDesignerNumber(round(h?.balance ?? 0).toFixed(2))}`}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={`${h?.date} ${idx}`}>
                        <td>{moment(h?.date).format('YY.MM.DD')}</td>
                        <td>Earning</td>
                        <td className='__name'>{h?.name_en}</td>
                        <td>$ {h?.price_usd}</td>
                        <td>{h?.fee_rate}%</td>
                        <td className='__fees'>
                          $ {formatDesignerNumber(round(h?.fees).toFixed(2))}
                        </td>
                        <td>
                          <span className='__incomes'>
                            + $ {formatDesignerNumber(round(h?.earning).toFixed(2))}
                          </span>
                        </td>
                        <td>$ {formatDesignerNumber(round(h?.balance).toFixed(2))}</td>
                      </tr>
                    );
                  }
                })}
              {isLoading && (
                <tr>
                  <td colSpan='8'>
                    <div className='nodata'>Loading Data to Display</div>
                  </td>
                </tr>
              )}
              {histories == false && (
                <tr>
                  <td colSpan='8'>
                    <div className='nodata'>No Data to Display</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagenation
        count={total}
        setPage={pageChange}
        page={page}
        onconfirm={pageConfirm}
        value={'my'}
        viewCount={PAGE_COUNT}
      />
    </History>
  );
};

export default BalanceHistory;

const History = styled.div`
  .__scroll-area {
    min-width: 1000px;
    overflow: scroll;
  }
`;
