import React, { useEffect, useState } from 'react';
import { FileModal, FileBtns } from './file';
import { FileBox } from '../designers/Contents/manageModelPage/manageEditStyle';
import { formatFileSize, formatFileTotal } from '../../util/formatFile';

import DownloadProcessingModal from '../modal/DownloadProcessingModal';
import { postFilesList, postDnSub, postSubDownloadStatus } from '../../action/request';
import FileName from './FileName';

const Selectefile = ({ setSelectedModal, id, setSelectedId, selectedName, status }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [downloadModalStep, setDownloadModalStep] = useState(0);
  const [filesList, setFilesList] = useState([]);
  const [selectedFileSize, setSelectedFileSize] = useState([]);
  const [jobId, setJobId] = useState();
  const [checkDownLoadStep, setCheckDownLoadStep] = useState(false);

  const onClickSelecteFiles = file => {
    setSelectedFiles(prevSelectedFiles => {
      if (prevSelectedFiles.includes(file?.id_file)) {
        setSelectedFileSize(
          selectedFileSize?.filter(fileIndex => fileIndex.id_file !== file?.id_file)
        );
        return prevSelectedFiles.filter(fileIndex => fileIndex !== file?.id_file);
      } else {
        setSelectedFileSize([...selectedFileSize, file]);
        return [...prevSelectedFiles, file?.id_file];
      }
    });
  };

  const onClickDeleteAll = () => {
    setSelectedFiles([]);
  };

  const checkJobStatus = (id, jobId) => {
    const intervalTime = 1000;
    let intervalId;

    const promise = new Promise((resolve, reject) => {
      intervalId = setInterval(async () => {
        try {
          const response = await postSubDownloadStatus(id, jobId);
          const data = response.data.data.status;
          if (data === 'complete') {
            clearInterval(intervalId);
            resolve(data);
          }
        } catch (error) {
          clearInterval(intervalId);
          reject(error);
        }
      }, intervalTime);
    });

    promise.stop = () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    return promise;
  };

  const onModalClose = jobPromise => {
    if (jobPromise && jobPromise.stop) {
      jobPromise.stop();
    }
  };

  const handleDownloadClick = async value => {
    try {
      if (value === 'allFiles' || selectedFiles?.length === filesList?.length) {
        setDownloadModalStep(1);
        setJobId(false);
      } else {
        const { data } = await postDnSub(id, { idFileItems: selectedFiles });
        if (data?.returnCode === 'C00000') {
          setDownloadModalStep(1);
          setJobId(data?.data?.job_id);
        } else {
          alert(data?.message);
        }
        checkJobStatus(id, data?.data?.job_id)
          .then(data => {
            setCheckDownLoadStep(data);
          })
          .catch(error => {
            alert(error);
            console.error('Error occurred:', error);
            setDownloadModalStep(0);
          });
      }
    } catch (e) {
      alert(e);
    }
  };

  async function fetchFileList(id) {
    try {
      const { data } = await postFilesList(id);
      if (data?.returnCode === 'C00000') {
        setFilesList(data?.result);
      } else {
        alert(data?.message);
      }
    } catch (e) {
      alert(e);
    }
  }

  const onClickClose = () => {
    setSelectedModal(false);
    setSelectedId(false);
    setFilesList([]);
    setSelectedFileSize(false);
    onClickDeleteAll();
    setCheckDownLoadStep(false);
  };

  useEffect(() => {
    id && fetchFileList(id);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // 스크롤 막기
    return () => {
      document.body.style.overflow = 'visible'; // 스크롤 막기
    };
  }, []);

  const fileItems = filesList?.map((file, key) => (
    <div className='file-item' key={key}>
      <button className='file-selecte' onClick={() => onClickSelecteFiles(file)}>
        {selectedFiles?.includes(file?.id_file) && <div className='selected-item' />}
      </button>
      <FileBox width={'100%'}>
        <div className='selectedfilebox'>
          <FileName content={file?.name} />

          <div className='file-size'>
            <span>{formatFileSize(file?.file_size).filesize}</span>
            <span className='gap'> {formatFileSize(file?.file_size).unit}</span>
          </div>
        </div>
      </FileBox>
    </div>
  ));

  return (
    <>
      {downloadModalStep > 0 && (
        <DownloadProcessingModal
          itemId={id}
          step={downloadModalStep}
          setStep={setDownloadModalStep}
          jobId={jobId}
          checkDownLoadStep={checkDownLoadStep}
          selectedName={selectedName}
          onClickClose={onClickClose}
          onModalClose={onModalClose}
        />
      )}

      {downloadModalStep === 0 && (
        <FileModal status={status}>
          <div className='filemodal-content'>
            <div className='filemodal-top'>
              <button className='close-btn' onClick={() => onClickClose()}>
                <img src='/assets/img/icon/close-button.png' />
              </button>
              <span>Select Files</span>
            </div>
            <div className='filemodal-files'>
              <div className='filemodal-list'>{fileItems}</div>
              <div className='filemodal-filesize'>
                <div className='file-wrap'>
                  <div>
                    <button className='delete-btn' onClick={() => onClickDeleteAll()}>
                      Deselect All
                    </button>
                    <span className='file-count'>
                      {selectedFiles === false ? 0 : selectedFiles?.length} / {filesList?.length}{' '}
                      {''}
                      files
                    </span>
                  </div>
                  <div className='file-total'>
                    <div className='file-selectedsize'>
                      <span className='file-gray margin'>
                        Selected Size :{' '}
                        <span className='file-size'>
                          {selectedFiles?.length === 0 ? (
                            '0 MB'
                          ) : (
                            <>
                              {formatFileTotal(selectedFileSize)?.filesize}{' '}
                              {formatFileTotal(selectedFileSize)?.unit}
                            </>
                          )}
                        </span>
                      </span>
                    </div>
                    <span className='file-gray margin'>
                      Total Size :{' '}
                      <span className='file-size'>
                        {formatFileTotal(filesList)?.filesize} {formatFileTotal(filesList)?.unit}
                      </span>
                    </span>
                    <span className='file-gray margin small'>(based on pre-compression size)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='files-btnwrap'>
              <FileBtns
                bg={selectedFiles?.length > 0 ? '#46B81E' : '#DFDFDF'}
                onClick={() => handleDownloadClick('selectedFiles')}
                disabled={selectedFiles?.length === 0}
              >
                Download Selected Files
              </FileBtns>
              <FileBtns bg={'#46B81E'} onClick={() => handleDownloadClick('allFiles')}>
                Download All
              </FileBtns>
            </div>
          </div>
        </FileModal>
      )}
    </>
  );
};

export default Selectefile;
