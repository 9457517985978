import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Webpage2StylesContainer } from '../../../styles/Webpage2Styles';

import UserContext from '../../../context/user/user';
import CartContext from '../../../context/cart.js/carts';
import ModalContext from '../../../context/modal/modal';
import { useContext } from 'react';
import { toggleItemLike, createCartAdd } from '../../../action/request';
import { updateLikeState } from '../../../util/updateLikeState';
import { gtagAddToCart } from '../../../action/gTag';

import { ls } from '../../../constant';
import ViewAllButton from '../../../component/common/ViewAllButton';
import { fillArray } from '../../../component/section/util';
import { scrollRefAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';
import ModelCard from '../../../component/common/itemCards/ModelCard';
import useClickHandler from '../../../component/common/itemCards/useCardClickHandeler';
import useDownload from '../../../hook/useDownload';
import Selectefile from '../../../component/selectefile/Selectefile';
import styled from 'styled-components';

const ItemDetailSwiper = ({
  title,
  options,
  index,
  cards,
  setCards,
  setLikeState,
  setDataValue,
  categoryName,
  studio,
  id,
  itemDataPage,
}) => {
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const navigate = useNavigate();
  const { state: userState } = useContext(UserContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const { action: modalAction } = useContext(ModalContext);
  const [, setScrollRef] = useRecoilState(scrollRefAtom);
  const fillCards = cards?.length <= 3;
  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    setScrollRef({ ref: 3, offsetTop: window?.scrollY, id: id });
    navigate(path);

    window.scrollTo(0, 0);
  };

  const handleViewAll = studio => {
    if (title === 'Designer’s other models') {
      navigate(`/designers/${studio.replace(/\s+/g, '')}`);
    } else {
      categoryName && navigate(`/categories/${categoryName}`);
    }
    setScrollRef({ ref: 3, offsetTop: window?.scrollY, id: id });
  };

  const clickLike = async (e, id, count) => {
    e.stopPropagation();
    if (!userState?.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeState,
          cards: cards,
          count: count,
          data: data,
        });
        setCards(updatedCards);
      }
    }

    setDataValue(index === 1 ? 2 : 1);
  };

  const clickCart = async (e, price, id, inCart) => {
    e.stopPropagation();
    // 이미 카트에 담겨있을 경우
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = cards.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setCards(newCards);

          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  return (
    <Webpage2StylesContainer itemDataPage={itemDataPage}>
      <BtnAll>
        <p className='title'>{title}</p>
        <ViewAllButton handleClick={() => handleViewAll(studio)} />
      </BtnAll>
      <ItemDetailWrap className='sectionTypeB itemDetailSwiper'>
        <div className='swiperBigWrap'>
          <Swiper {...options} modules={[Navigation]} className='sectionASwiper sectionASwiper004'>
            <div className='swiper-wrapper'>
              {cards &&
                fillArray([...cards], { product_id: 'false' }, fillCards ? 4 : 0)?.map(
                  (card, idx) => {
                    return (
                      <SwiperSlide key={idx}>
                        <div className='swiper-slide'>
                          <ModelCard
                            handleClick={() => handleClick(idx)}
                            twoClick={twoClick === idx}
                            oneClick={oneClick === idx}
                            data={card}
                            clickLike={clickLike}
                            clickCart={clickCart}
                            onClickDownload={onClickDownload}
                            buyStatus={card.buy_status}
                            downloadStatus={card.download_status}
                            isDesigner={card.isDesigner && userState.isLogin}
                            isOwn={card?.isOwn && userState.isLogin}
                          />
                        </div>
                      </SwiperSlide>
                    );
                  }
                )}
            </div>
          </Swiper>

          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperPrev buttonSwiperPrevS4${index}`}
            title='prev'
          ></button>
          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperNext buttonSwiperNextS4${index}`}
            title='next'
          ></button>
        </div>
        {selectedId && selectedModal && (
          <Selectefile
            setSelectedModal={setSelectedModal}
            id={selectedId}
            setSelectedId={setSelectedId}
            selectedName={selectedName}
            status={true}
          />
        )}
      </ItemDetailWrap>
    </Webpage2StylesContainer>
  );
};

export default ItemDetailSwiper;

const BtnAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemDetailWrap = styled.div`
  padding: 0px !important;

  @media screen and (max-width: 480px) {
    margin-left: 30px;
  }
`;
