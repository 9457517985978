/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { DEFAULT_AVATAR } from '../../avatar/const';
import { useRef } from 'react';
import { uploadItemImage, postDesignerCommentsWrite } from '../../../action/request';
import { imgHtml } from '../../../util/comment';

import { ImgStyled } from '../../../styles/ImgStyled';
import styled from 'styled-components';

const CommentBox = ({ studioId, onReload, userState }) => {
  const [editBody, setEditBody] = useState('');
  const [thumbs, setThumbs] = useState([]);
  const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState(false);
  const imageInput = useRef();

  const imageChanged = async e => {
    try {
      const files = e?.target.files;
      const newMedias = [...medias];
      const newThumbs = [...thumbs];
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadItemImage({
          file: files[i],
        });
        newThumbs?.push(imgHtml(res.img.thumbs[0].url));
        newMedias?.push(res.img);
      }
      setMedias(newMedias);
      setThumbs(newThumbs);
      setLoading(false);
    } catch (e) {
      alert('request failed');
    }
  };

  const initParams = () => {
    setEditBody('');
    setThumbs([]);
    setMedias([]);
  };

  const onDeleteImage = idx => {
    const newMedias = medias?.filter((media, i) => i !== idx);
    const newThumbs = thumbs?.filter((thumb, i) => i !== idx);
    setMedias(newMedias);
    setThumbs(newThumbs);
  };

  const onCreateComment = async e => {
    e.stopPropagation();
    e.preventDefault();

    if (!editBody || editBody === '') {
      return alert('Comment is empty');
    } else {
      try {
        const { data: res } = await postDesignerCommentsWrite(studioId, {
          root: '',
          parent: 0,
          studioId: Number(studioId),
          msg: editBody,
          'imgs[]': medias.map(media => JSON.stringify(media)),
          medias,
          thumbs: [{ size: '200x200', url: userState?.thumbnail }],
        });

        if (res.returnCode === 'C00000') {
          onReload();
          setEditBody('');
          setThumbs([]);
        } else {
          alert(res?.message);
        }
      } catch (e) {
        alert(e);
      }
    }
  };

  return (
    <Comment>
      <div className='comments-header'>
        <div className='comment-write'>
          <div className='profile'>
            <div className='profile-pic'>
              <ImgStyled
                src={userState?.thumbnail || DEFAULT_AVATAR}
                alt=''
                style={{ height: '100%', objectFit: 'cover' }}
              />
              {userState?.isDesigner && Number(studioId) === Number(userState?.idStudio) ? (
                <img src={'/assets/img/designers/crown.png'} className='crown' />
              ) : (
                ''
              )}
            </div>
          </div>
          <form className='comment-write-form' data-action='comment-write'>
            <div className='write-form'>
              <textarea
                placeholder='Please input your text'
                name='msg'
                value={editBody}
                onChange={e => setEditBody(e.target.value)}
              ></textarea>
              <div className='attachments'>
                <ul className='files'>
                  {thumbs?.map((thumb, idx) => {
                    return (
                      <li key={idx + thumb.url}>
                        <div className='file-item'>
                          <a>
                            <ImgStyled src={thumb?.url} alt='' />
                          </a>
                          <button
                            type='button'
                            className='btn-delete'
                            onClick={() => onDeleteImage(idx)}
                          >
                            ×
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className='form-action'>
                <div className='file-uploader'>
                  <input
                    type='file'
                    accept='image/*'
                    multiple
                    ref={imageInput}
                    onChange={imageChanged}
                  />
                  <button
                    type='button'
                    className={'btn-upload-file loading_color ' + (loading && 'loading')}
                    onClick={() => imageInput.current.click()}
                  >
                    Add Image
                  </button>
                </div>
                <button
                  type='submit'
                  className='btn btn-green btn-fill'
                  onClick={onCreateComment}
                  id='addBtn'
                >
                  Add Comments
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Comment>
  );
};

export default CommentBox;

const Comment = styled.div`
  max-width: 900px;
`;
