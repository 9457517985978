import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Dropdown from '../../../component/designers/Dropdown';
import { getDesignerList } from '../../../action/request';
import UserContext from '../../../context/user/user';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  idsAtom,
  selectedIdIndexAtom,
  designerListAtom,
  selectedSortTypeAtom,
  selectedSortDirAtom,
  preDesignerListAtom,
  designerTotalAtom,
} from '../../../atom/atom';
import DesignerListHeader from '../../../component/designers/Header/DesignerListHeader';
import DesignerSectionBody from './DesignerSectionBody';
import { throttle } from 'lodash';
import useResetDesignerState from '../../../hook/useResetDesignerState';

const DesignerSection = () => {
  const { state: userData } = useContext(UserContext);
  const [selectedSortType, setSelectedSortType] = useRecoilState(selectedSortTypeAtom);
  const [selectedSortDir, setSelectedSortDir] = useRecoilState(selectedSortDirAtom);
  const [designerIdList, setDesignerIdList] = useRecoilState(idsAtom);
  const [selectedIdIndex, setSelectedIdIndex] = useRecoilState(selectedIdIndexAtom);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);
  const [total, setTotal] = useRecoilState(designerTotalAtom);
  const [hasMore, setHasMore] = useState(true);
  const [preDesigner, setPreDesigner] = useRecoilState(preDesignerListAtom);
  const scrollPosition = localStorage.getItem('scrollDesigner');
  const resetAllDesignerState = useResetDesignerState(true);
  const progressModalY = localStorage.getItem('progressModalY');
  const token = localStorage?.getItem('accessToken');

  const isFetching = useRef(false);

  const sortDirOptions = [
    { title: 'Descending', value: 'Descending' },
    { title: 'Ascending', value: 'Ascending' },
  ];

  const sortTypeOptions = [
    { title: 'Name', value: 'Name' },
    { title: 'Downloads', value: 'Downloads' },
    { title: 'Newest', value: 'Newest' },
    { title: 'Models', value: 'Models' },
  ];

  const onChangeSortDirt = e => {
    if (!e || !e.value) return;

    if (selectedSortType?.value === 'Random') return;

    setSelectedSortDir(e);
    setDesignerList([]);
    setDesignerIdList('');
    setPreDesigner(false);
    setSelectedIdIndex(0);
    setTotal('');
    setHasMore(true);
  };

  const onChangeSortType = e => {
    if (!e || !e.value) return;

    const isNameSort = e.value === 'Name';
    setSelectedSortDir(isNameSort ? sortDirOptions[1] : sortDirOptions[0]);
    setSelectedSortType(e);
    setHasMore(true);
    setDesignerList([]);
    setDesignerIdList('');
    setPreDesigner(false);
    setTotal('');
    setSelectedIdIndex(0);
  };

  const fetchDataDesignerList = useCallback(async () => {
    if (progressModalY) return;
    if (
      (designerIdList !== '' && designerIdList?.length === selectedIdIndex) ||
      (designerIdList !== '' && !designerIdList[selectedIdIndex])
    )
      return;

    try {
      const isLogin = token ? true : false;

      const { data } = await getDesignerList(isLogin, {
        ids:
          designerIdList === '' || selectedIdIndex === 0
            ? ''
            : designerIdList[selectedIdIndex]?.join(','),
        sortType: selectedSortType?.value,
        sortDir: selectedSortDir?.value,
        offset: 0,
        size: 5,
      });

      const sIdsArr = data?.data?.pagingInfo?.ids || '';
      const idsArray = sIdsArr.split(',').map(id => id.trim());
      const chunkSize = 5;
      const chunks = [];
      for (let i = 0; i < idsArray.length; i += chunkSize) {
        chunks.push(idsArray.slice(i, i + chunkSize));
      }

      if (selectedIdIndex === 0 || designerIdList === '') {
        setDesignerIdList(chunks);
      }

      const resultDataList = data?.data?.list || [];

      if (resultDataList?.length > 0) {
        if (idsArray.length === data?.data?.total) {
          setDesignerList(resultDataList);
          setSelectedIdIndex(1);
        } else {
          setDesignerList(prev => {
            const seenStudios = new Set(prev.map(d => d?.designer?.id_studio));
            const uniqueDesigners = resultDataList.filter(
              d => !seenStudios.has(d?.designer?.id_studio)
            );

            uniqueDesigners.forEach(d => seenStudios.add(d?.designer?.id_studio));

            return [...prev, ...uniqueDesigners];
          });
          setSelectedIdIndex(prevIndex => prevIndex + 1);
        }
      } else {
        setHasMore(false);
      }

      setTotal(data?.data?.total);
      setPreDesigner(false);
    } catch (error) {
      console.error('error-designerlist', error);
      setHasMore(false);
    } finally {
      isFetching.current = false;
    }
  }, [selectedSortType, selectedSortDir, designerList, token]);

  const handleScroll = useCallback(
    throttle(() => {
      const { scrollTop, scrollHeight } = document.documentElement;
      const windowHeight = window.innerHeight;

      if (scrollTop + windowHeight >= scrollHeight - 50) {
        if (!hasMore || isFetching?.current) return;
        if (designerList?.length === total) return;

        isFetching.current = true;
        setPreDesigner(false);
        fetchDataDesignerList().finally(() => {
          isFetching.current = false;
        });
      }
    }, 300),
    [fetchDataDesignerList, hasMore]
  );

  useEffect(() => {
    if (scrollPosition) {
      setTimeout(() => window.scrollTo(0, scrollPosition), 500);
    }

    if (!preDesigner) {
      window.scrollTo(0, 0);
      fetchDataDesignerList();
    }
  }, []);

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 300);

    window.addEventListener('scroll', throttledHandleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const retryFetch = setTimeout(() => {
      if (designerList?.length === 0 || !designerList) {
        isFetching.current = true;
        fetchDataDesignerList();
      }
    }, 1500);

    return () => clearTimeout(retryFetch);
  }, [designerList?.length, userData?.isLogin, fetchDataDesignerList]);

  useEffect(() => {
    const resetState = event => {
      const progressModalY = localStorage.getItem('progressModalY');
      if (progressModalY || !preDesigner) return;
      resetAllDesignerState();
    };

    if (!preDesigner) {
      setDesignerList([]);
      setDesignerIdList('');
      setPreDesigner(false);
      setSelectedIdIndex(0);
      setTotal(0);
      setSelectedSortType({ title: 'Random', value: 'Random' });
      setSelectedSortDir({ title: 'Default', value: 'Default' });
    }

    window.addEventListener('beforeunload', resetState);
    window.addEventListener('pagehide', resetState);
    return () => {
      window.removeEventListener('beforeunload', resetState);
      window.removeEventListener('pagehide', resetState);
    };
  }, []);

  return (
    <DesignerSectionWrap>
      <div className='dropdown-section'>
        <Dropdown
          options={sortTypeOptions}
          onChangeSort={onChangeSortType}
          selectedSort={selectedSortType}
        />
        <Dropdown
          options={sortDirOptions}
          onChangeSort={onChangeSortDirt}
          selectedSort={selectedSortDir}
          value={selectedSortType?.value === 'Random'}
        />
      </div>
      <ListSectionWrap>
        {/* <h2 style={{ fontSize: 40 }}>{designerList?.length}</h2> */}
        {designerList &&
          designerList?.map((list, index) => (
            <div key={index} className='designer-list'>
              <DesignerListHeader
                data={list?.designer}
                isLogin={userData?.isLogin}
                designerList={designerList}
                setDesignerList={setDesignerList}
              />
              <DesignerSectionBody
                data={list?.items}
                designerId={list?.designer?.id_studio}
                locationData={index}
              />
            </div>
          ))}
        {hasMore && <div className='loading'></div>}
      </ListSectionWrap>
    </DesignerSectionWrap>
  );
};

export default DesignerSection;

const DesignerSectionWrap = styled.div`
  max-width: 1340px;
  min-height: 500px;
  margin: 0px auto;
  z-index: 999;

  .dropdown-section {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 50;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

const ListSectionWrap = styled.div`
  .designer-list {
    margin-bottom: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
  }
  .loading {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: gray;
  }
`;
