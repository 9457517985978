import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ManageEdit from '../manageModelPage/ManageEdit';
import { editModelAtom, manageEditIdAtom } from '../../../../atom/atom';
import { useRecoilState } from 'recoil';
import { getEditModelInfo, getDesignerManageCategory } from '../../../../action/request';
import { StatusValue } from '../manageModelPage/manageEditStyle';
import { getColorByStatus, getByStatus } from '../../../../util/managemodel';
import { useNavigate } from 'react-router-dom';

const UploadModels = ({ setContent, designerURL }) => {
  const [editManageId, setEditManageId] = useRecoilState(manageEditIdAtom);
  const [editModelData, setEditModelData] = useState();
  const [ctgList, setCtgList] = useState();
  const [status, setStatus] = useState('draft');
  const [topBackBtn, setTopBackBtn] = useState(false);
  const [editModel, setEditModel] = useRecoilState(editModelAtom);
  const navigate = useNavigate();
  const updateClick = () => {
    setContent('list');
    setEditManageId(false);
    setEditModelData('');
  };

  const fetchEditModel = async () => {
    const { data: modelData } = await getEditModelInfo(editModel?.id ? editModel.id : editManageId);
    if (modelData?.returnCode === 'C00000') {
      setEditModelData(modelData.data);
    } else {
      alert(modelData?.message);
    }
  };
  const fetchDesignerCtg = async () => {
    const { data: ctgData } = await getDesignerManageCategory();
    if (ctgData?.returnCode === 'C00000') {
      setCtgList(ctgData.data);
    } else {
      alert(ctgData?.message);
    }
  };

  useEffect(() => {
    fetchDesignerCtg();
  }, []);

  useEffect(() => {
    if (editManageId !== false || editModel?.id !== null) fetchEditModel();
  }, [editManageId !== false, editModel?.id !== null]);

  useEffect(() => {
    if (editModel?.status) {
      setTimeout(() => {
        window.scrollTo(0, 360);
      }, 300);
    }
  }, []);

  return (
    <UploadModel>
      <div className='upload-top'>
        <button className='back-list' onClick={() => setTopBackBtn(!topBackBtn)}>
          {'<'} Back to the list
        </button>
        <div className='title-wrap'>
          <p className='title'>Model Upload/Edit</p>
          <span className='status'>
            Status:{' '}
            <StatusValue
              className='status-value'
              color={editModelData ? getColorByStatus(status) : '#969696'}
            >
              {editModelData ? getByStatus(status) : 'Draft'}
            </StatusValue>
          </span>
        </div>
      </div>
      <ManageEdit
        designerURL={designerURL}
        editModelData={editModelData}
        setEditModelData={setEditModelData}
        ctgList={ctgList}
        updateClick={updateClick}
        setStatus={setStatus}
        status={status}
        setContent={setContent}
        topBackBtn={topBackBtn}
        setTopBackBtn={setTopBackBtn}
      />
    </UploadModel>
  );
};

export default UploadModels;

const UploadModel = styled.div`
  font-family: 'Inter', sans-serif;

  button.back-list {
    font-family: 'Inter', sans-serif;
    margin-top: 50px;
    color: #000 !important;
  }
  .upload-top {
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .back-list {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 30px;
      font-weight: 700;
    }
    .status {
      font-size: 20px;
      font-weight: 500;
    }
  }
`;
