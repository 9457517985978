import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSearchParams } from 'react-router-dom';
import UserContext from '../../../context/user/user';

const DesignersNav = ({ selectedTab, onClickTab, userState, designerURL }) => {
  const swiperRef = useRef(null); // Swiper 인스턴스를 참조하기 위한 useRef 훅
  const [searchParams] = useSearchParams();
  const { state: userData, action: userAction } = useContext(UserContext);
  const isDesigner = userState.isDesigner && Number(userState.idStudio) === Number(designerURL);
  const data = [
    { title: 'Models', value: 0 },
    { title: 'Followers', value: 1 },
    { title: 'Comments', value: 2 },
    { title: 'Reivews', value: 3 },
  ];

  const designerNav = [
    { title: 'Manage Models', value: 4 },
    { title: 'My Sales', value: 5 },
    // { title: 'App Push', value: 6 },
  ];

  const options = {
    slidesPerView: 'auto',
    centeredSlides: false,
    spaceBetween: 0,
    loop: false,
    onSwiper: swiper => {
      swiperRef.current = swiper; // Swiper 인스턴스를 참조
    },
  };

  const handleTabClick = nav => {
    onClickTab(nav); // 기존 클릭 이벤트
    const index = [...data, ...designerNav].findIndex(item => item.value === nav.value);
    swiperRef.current.slideTo(index);
  };

  useEffect(() => {
    swiperRef.current.slideTo(selectedTab);

    if (searchParams?.get('selectedTabID')) {
      userAction.setIsApp(true);
    } else {
      // setIsFromApp(false);
      userAction.setIsApp(false);
    }

    if (searchParams?.get('selectedTabID')) {
      handleTabClick({ value: +searchParams?.get('selectedTabID') });
    }
  }, [selectedTab]);

  return (
    <Section>
      <Nav {...options}>
        {data.map((nav, key) => (
          <Tab
            key={key}
            onClick={() => handleTabClick(nav)} // 핸들러 함수 호출
            border={selectedTab === nav.value ? 3 : 0}
            width={120}
          >
            <TabTitle color={selectedTab === nav.value ? '#000' : '#9A9A9A'}>{nav.title}</TabTitle>
          </Tab>
        ))}
        {isDesigner && (
          <div className='designerNav'>
            {designerNav.map((nav, key) => (
              <MyTab
                key={key}
                onClick={() => handleTabClick(nav)} // 핸들러 함수 호출
                border={selectedTab === nav?.value ? 3 : 0}
                width={key === 0 ? 170 : 120}
                right={key === 0 ? 150 : 0}
              >
                <TabTitle color={selectedTab === nav.value ? '#000' : '#9A9A9A'}>
                  {nav?.title}
                </TabTitle>
              </MyTab>
            ))}
          </div>
        )}
      </Nav>
    </Section>
  );
};

export default DesignersNav;

const Nav = styled(Swiper)`
  /* width: 100%; */
  display: flex;
  margin-top: 20px;
  border-bottom: 1px solid #cdcfd3;
  margin-bottom: 20px;
`;

const Tab = styled(SwiperSlide)`
  width: ${props => props.width}px;
  height: 40px;
  display: flex;
  justify-content: center;
  border-bottom: ${props => props.border}px solid #000 !important;
  padding: 0px 10px;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }
`;

const MyTab = styled(Tab)``;

const TabTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.color};
  margin-bottom: 12px;
`;

const Section = styled.section`
  color: red;

  .swiper-wrapper:first-child > div:nth-child(4) {
    margin-right: auto;
  }
`;
