import { useEffect, useState, useRef } from 'react';
import { ManageEditSection } from './manageEditStyle';
import ManageButtonAction from '../../../mangemodel/editModel/ManageButtonAction';
import RequiredInfo from './RequiredInfo';
import AdditionalInfo from './AdditionalInfo';
import QuilEditor from '../../../editor/QuillEditor';
import ManageModal from '../../../mangemodel/common/ManageModal';
import { getByModalContantDraft } from '../../../../util/managemodel';
import { postManageModelEdit } from '../../../../action/request';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { editModelAtom, managePrevAtom, manageProcessingAtom } from '../../../../atom/atom';
import { useRecoilState } from 'recoil';
import ProcessingModal from '../../../mangemodel/common/ProcessingModal';
import closeProcessing from '../../../mangemodel/common/closeProcessing';
import { createCompareObj } from '../../util/createCompareObj';

const ManageEdit = ({
  designerURL,
  editModelData,
  setEditModelData,
  ctgList,
  updateClick,
  setStatus,
  status,
  setContent,
  topBackBtn,
  setTopBackBtn,
}) => {
  const requiredInfoRef = useRef(null);

  function imgnewArr(length) {
    return Array.from({ length: length }, () => ({ value: 'add', img: 'add' }));
  }
  const navigate = useNavigate();
  const [updateModelData, setUpdateModelData] = useState({ value: false, id: '' });
  const [editModel, setEditModel] = useRecoilState(editModelAtom);
  const [newModelData, setNewModelData] = useState();
  const [, setPrevState] = useRecoilState(managePrevAtom);
  const [priceOption, setPriceOption] = useState({ name: 'Paid', value: 'paid' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [processing, setProcessing] = useRecoilState(manageProcessingAtom);
  const [value, setValue] = useState('');

  // Required Information
  const [requiredData, setRequiredData] = useState({
    modelName: '',
    priceUSD: '',
    discountRate: '',
  });
  const [fileList, setFileList] = useState([]);
  const [coverImg, setCoverImg] = useState({
    listImg4c: '',
    listImg3c: '',
    listImg: '',
  });
  const [coverImgHover, setCoverImgHover] = useState({
    listImg4g: '',
    listImg3g: '',
    listImg2: '',
  });
  const [detailImg, setDetailImg] = useState(imgnewArr(5));
  const [failed, setFailed] = useState(false);
  const [requiredInfo, setRequiredInfo] = useState({
    requiredName: false,
    requiredFiles: false,
    requiredPrice: false,
    requiredCoverImg: false,
    requiredDetailImg: false,
    requiredCtg: false,
  });

  //Additional Information
  const [addInfo, setAddInfo] = useState({
    printingTime: '',
    totalWeight: '',
    largestPartSize: { xAxis: '', yAxis: '', zAxis: '' },
  });

  const [dropSelectValue, setDropSelectValue] = useState('');
  const [tag, setTag] = useState([]);

  const [modalContant, setModalContant] = useState();
  const [mouseBack, setMouseBack] = useState(false);

  const [idItem, setIdItem] = useState('');

  const [deleteFiles, setDeleteFiles] = useState([]);
  const [isAbled, setIsAbled] = useState(false);

  const { modelName, priceUSD, discountRate } = requiredData;
  const { printingTime, totalWeight, largestPartSize } = addInfo;

  function calculateDiscountedPrice(originalPrice, discountRate) {
    if (originalPrice === 0 && discountRate === 0) {
      return '0.00';
    }

    if (originalPrice || discountRate) {
      var subTotal = (originalPrice * discountRate) / 100;
      subTotal = Math.floor(subTotal * 100) / 100;
      var finalPrice = Number(originalPrice) - subTotal;

      finalPrice = finalPrice?.toString();

      const decimalIndex = finalPrice?.indexOf('.');

      if (decimalIndex === -1) {
        finalPrice += '.00';
      } else {
        // 소수점이 있을 경우
        const decimalPart = finalPrice?.substring(decimalIndex + 1);
        if (decimalPart?.length > 2) {
          finalPrice = finalPrice?.substring(0, decimalIndex + 3);
        } else if (decimalPart?.length === 1) {
          finalPrice += '0';
        }
      }

      return finalPrice;
    }
  }

  function newFileList(fileList) {
    const cleanedData = fileList?.map(({ file_url, file_size, date_upload }) => ({
      file_url,
      file_size,
      date_upload,
    }));

    const filterList = cleanedData?.filter(el => el.file_url);
    return filterList;
  }

  const onClick = () => {
    const filterDeatil = detailImg?.filter(item => item?.value !== 'add');
    let sortCounter = filterDeatil?.length;

    const detailImgList = filterDeatil?.map(item => {
      if (!item.value) {
        item.sort = sortCounter--;
      }
      return item;
    });

    const filterfileList = newFileList(fileList);

    const data = {
      idStudio: designerURL,
      idItem: idItem,
      status: status,
      name: modelName,
      originalPrice: priceUSD,
      discountRate: discountRate,
      finalPrice: calculateDiscountedPrice(priceUSD, discountRate),
      idCategory: dropSelectValue ? dropSelectValue?.id_category : '',
      tags: tag
        ?.filter(item => item.isValid)
        .map(item => item.value.trim())
        .join(','),
      printingTime: printingTime,
      totalWeight: totalWeight,
      partSizeX: largestPartSize?.xAxis,
      partSizeY: largestPartSize?.yAxis,
      partSizeZ: largestPartSize?.zAxis,
      description: value,
      files: filterfileList,
      detailImg: detailImgList,
      priceOption: priceOption?.value,
      listImg4c: !coverImg?.listImg4c ? '' : coverImg?.listImg4c,
      listImg3c: !coverImg?.listImg3c ? '' : coverImg?.listImg3c,
      listImg: !coverImg?.listImg ? '' : coverImg?.listImg,
      listImg4g: !coverImgHover?.listImg4g ? '' : coverImgHover?.listImg4g,
      listImg3g: !coverImgHover?.listImg3g ? '' : coverImgHover?.listImg3g,
      listImg2: !coverImgHover?.listImg2 ? '' : coverImgHover?.listImg2,
    };

    return data;
  };

  const onClickAddImg = () => {
    if (detailImg?.length < 20) {
      setDetailImg([
        ...detailImg,
        ...imgnewArr(detailImg?.length + 5 > 20 ? 20 - detailImg?.length : 5),
      ]);
    }
  };

  const handleInputChange = description => {
    const formattedText = description?.replace(/\r\n/g, '<br>').replace(/\n/g, '<br>');
    setValue(formattedText);
  };

  useEffect(() => {
    if (!editModelData) return;

    const {
      lpart_size_x: size_x,
      lpart_size_y: size_y,
      lpart_size_z: size_z,
      description,
      detail_img: detailImgList,
      tags: tagList,
      id_item,
      status,
      name: modelName,
      original_price,
      discount_rate,
      print_time,
      total_weight,
      list_img,
      list_img2,
      list_img_3g,
      list_img_3c,
      list_img_4c,
      list_img_4g,
      category_name,
      files,
      price_option,
    } = editModelData;

    const resultArray = !tagList
      ? []
      : tagList?.split(',').map(item => ({ value: item.trim(), isValid: true }));

    const detailImg =
      detailImgList?.length === 0
        ? imgnewArr(5)
        : detailImgList?.map(({ sort, img }) => ({ sort, img }));

    const filterCtg = ctgList?.filter(el => el?.name === category_name);
    setTag(resultArray);
    handleInputChange(description);
    setIdItem(id_item);
    setStatus(status);
    setRequiredData({
      modelName,
      priceUSD: Number(original_price),
      discountRate: Number(discount_rate),
    });
    setAddInfo({
      printingTime: print_time || '',
      totalWeight: total_weight || '',
      largestPartSize: {
        xAxis: size_x || '',
        yAxis: size_y || '',
        zAxis: size_z || '',
      },
    });
    setCoverImg({
      listImg4c: list_img_4c,
      listImg3c: list_img_3c,
      listImg: list_img,
    });
    setCoverImgHover({
      listImg4g: list_img_4g,
      listImg3g: list_img_3g,
      listImg2: list_img2,
    });
    setDetailImg(detailImg);
    setDropSelectValue(filterCtg?.[0]);
    setFileList(files);
    setPriceOption(
      price_option === 'paid' ? { name: 'Paid', value: 'paid' } : { name: 'Free', value: 'free' }
    );
  }, [editModelData]);

  function compareJSON(obj1, obj2) {
    const differences = {};
    for (const key in obj1) {
      let value1 = obj1[key];
      let value2 = obj2[key];

      if (Array.isArray(value1) && Array.isArray(value2)) {
        if (value1?.length !== value2?.length) {
          differences[key] = {
            value1: obj1[key],
            value2: obj2[key],
          };
        } else {
          const value1Imgs = value1?.map(item => item?.img || item?.file_url).sort();
          const value2Imgs = value2?.map(item => item?.img || item?.file_url).sort();

          if (JSON.stringify(value1Imgs) !== JSON.stringify(value2Imgs)) {
            differences[key] = {
              value1: value1,
              value2: value2,
            };
          }
        }
      } else if (value1 !== value2) {
        differences[key] = {
          value1: obj1[key],
          value2: obj2[key],
        };
      }
    }

    return differences;
  }

  const onClickBackBtn = () => {
    const isValidData = updateModelData?.value
      ? newModelData
      : createCompareObj(editModelData, 'initialValue');

    const isSameNewModelData = compareJSON(createCompareObj(onClick(), 'changeValue'), isValidData);

    if (Object.keys(isSameNewModelData)?.length === 0) {
      setContent('list');
      if (editModel?.detailPage && mouseBack) {
        navigate(`/items/${idItem}`);
      }
      setEditModel(pre => ({
        detailPage: false,
        id: null,
        status: false,
      }));
      return;
    } else {
      setModalIsOpen(true);
      const content = getByModalContantDraft('back');
      setModalContant(content);
    }

    setTopBackBtn(false);
  };

  const onClickBtn = (btnValue, secret, value) => {
    setModalIsOpen(true);
    if (secret === 'secret') {
      const content = getByModalContantDraft('resume');
      setModalContant(content);
    } else {
      const contentKey = value ? btnValue + value : btnValue;
      const content = getByModalContantDraft(contentKey);
      if (content?.value === 'failed') {
        setFailed(true);
      }
      setModalContant(content);
    }
    setModalIsOpen(true);
  };

  // 모달 창 열기

  const scrollToRequiredInfo = () => {
    if (requiredInfoRef?.current) {
      requiredInfoRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth', top: -70 });
    }
  };

  function isValidTag() {
    const tagfilter = tag?.filter(el => el?.isValid !== false);
    setTag(tagfilter);
  }

  function finalimg(detailImgList) {
    const maxImages = Math?.min(detailImgList?.length, 15);
    setDetailImg(detailImg?.slice(0, maxImages));
  }

  const fetchModel = async (action, status, secret) => {
    const isEdit = action === 'edit';
    const isPublished = status === 'draftpublish';
    const isbtnValue = isPublished ? 'public' : status;

    const apiCall = postManageModelEdit;
    const result = onClick();
    result.status = isbtnValue;

    try {
      setProcessing(true);

      const { data: modelResponse } = await apiCall(result);
      if (modelResponse?.returnCode === 'C00000') {
        if (status === 'public' || isPublished) {
          const btnValue = isEdit ? 'doneupdated' : 'donepublished';
          onClickBtn(isPublished ? 'donepublished' : btnValue, secret);
        } else if (status === 'secret' && isEdit) {
          updateClick();
        }
      } else {
        return alert(modelResponse?.message);
      }
      const manageId = modelResponse?.result?.id_item
        ? modelResponse?.result?.id_item
        : result?.idItem;

      isValidTag();
      setStatus(isbtnValue);
      finalimg(result?.detailImg);
      setIsAbled(false);
      setIdItem(manageId);
      setUpdateModelData({
        value: true,
        id: manageId,
      });
      setNewModelData(createCompareObj(result, 'changeValue'));
    } catch (error) {
      alert(error);
      console.error(`Error fetching ${isEdit ? 'edit' : 'add'} model:`, error);
    } finally {
      closeProcessing(setProcessing);
    }
  };

  function requiredCheck() {
    const validFiles = fileList?.filter(file => file.file_url);
    const isEmptyObject = fileList?.filter(el => Object.keys(el)?.length === 0);

    const list = {
      requiredName: !modelName,
      requiredFiles: validFiles?.length === 0,
      requiredFilesLoading: isEmptyObject?.length >= 1,
      requiredPrice:
        priceOption.value === 'paid' &&
        (!priceUSD || calculateDiscountedPrice(priceUSD, discountRate) < 1),
      requiredCoverImg: !coverImg?.listImg,
      requiredDetailImg: detailImg[0]?.value === 'add' || !detailImg[0]?.img,
      requiredCtg: !dropSelectValue,
    };

    setRequiredInfo(list);
    return list;
  }

  const handleButtonClick = e => {
    const value = modalContant?.value;

    // "back" 버튼 처리
    if (value === 'back') {
      updateClick();
      setModalIsOpen(false);
      if (editModel.detailPage && mouseBack) {
        navigate(`/items/${idItem}`);
      }
      setEditModel({
        detailPage: false,
        id: null,
        status: false,
      });
      return;
    }

    // "draft" 버튼 처리
    if (value === 'draft') {
      const action = 'edit';
      fetchModel(action, value);
      setModalIsOpen(false);
      return;
    }

    // "public" 또는 "update" 버튼 처리
    if (value === 'public' || value === 'update') {
      const resultCheck = requiredCheck();
      const action = 'edit';

      if (Object.values(resultCheck).some(value => value === true)) {
        onClickBtn('failed', '', value);
        return;
      }
      setStatus(value);
      setProcessing(true);
      fetchModel(action, status === 'draft' && idItem ? 'draftpublish' : 'public');
      return;
    }

    // "secret" 버튼 처리
    if (value === 'secret') {
      fetchModel('edit', 'public', 'secret');
      return;
    }

    // 완료된 상태 처리
    if (['doneupdated', 'donepublished', 'resume'].includes(value)) {
      navigate(`/items/${idItem}`);
      setPrevState(false);
      return;
    }

    // "stop" 버튼 처리
    if (value === 'stop') {
      setIsAbled(true);
      fetchModel('edit', 'secret');
      setEditModel({
        id: null,
        status: false,
      });
    }
  };

  const onClickStopSelling = () => {
    onClickBtn('stop');
  };

  useEffect(() => {
    const handlePopState = () => {
      localStorage.setItem('designerScrollY', window?.scrollY);
      setTopBackBtn(true);
      setMouseBack(true);
      if (localStorage?.getItem('designerScrollY')) {
        setTimeout(() => {
          window.scrollTo(0, localStorage?.getItem('designerScrollY'));
        }, 50);
      }

      window.history.pushState(null, document?.title, window?.location?.href);
    };

    window.history.pushState(null, document?.title, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      setEditModel({
        id: null,
        status: false,
        detailPage: false,
      });
    };
  }, []);

  useEffect(() => {
    if (topBackBtn) {
      onClickBackBtn();
    }
  }, [topBackBtn]);

  useEffect(() => {
    // 페이지가 렌더링될 때 스크롤 위치를 0으로 설정
    window?.scrollTo(0, 0);
  }, [updateModelData?.value]);

  return (
    <ManageEditContainer>
      <BlurSection>
        {status === 'secret' && <BlurBox />}
        <ManageModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          handleButtonClick={handleButtonClick}
          modalTitle={modalContant?.title}
          titleBold={400}
          modalContent={modalContant?.modalContent}
          btnContents={modalContant?.btnContents}
          scrollToRequiredInfo={scrollToRequiredInfo}
          value={modalContant?.value}
          setIsAbled={setIsAbled}
          isAbled={isAbled}
          setTopBackBtn={setTopBackBtn}
        />
        <ManageEditContainer>
          <div ref={requiredInfoRef}>
            {processing && <ProcessingModal />}

            <RequiredInfo
              setRequiredData={setRequiredData}
              modelName={modelName}
              requiredData={requiredData}
              priceUSD={priceUSD}
              discountRate={discountRate}
              dropSelectValue={dropSelectValue}
              setDropSelectValue={setDropSelectValue}
              detailImg={detailImg}
              setDetailImg={setDetailImg}
              onClickAddImg={onClickAddImg}
              designerURL={designerURL}
              coverImg={coverImg}
              setCoverImg={setCoverImg}
              coverImgHover={coverImgHover}
              setCoverImgHover={setCoverImgHover}
              ctgList={ctgList}
              idItem={editModelData?.id_item}
              fileList={fileList}
              setFileList={setFileList}
              requiredInfo={requiredInfo}
              setDeleteFiles={setDeleteFiles}
              calculateDiscountedPrice={calculateDiscountedPrice}
              deleteFiles={deleteFiles}
              failed={failed}
              setFailed={setFailed}
              modalIsOpen={modalIsOpen}
              setPriceOption={setPriceOption}
              priceOption={priceOption}
            />
          </div>
          <AdditionalInfo
            setAddInfo={setAddInfo}
            addInfo={addInfo}
            tag={tag}
            setTag={setTag}
            largestPartSize={largestPartSize}
            totalWeight={totalWeight}
            printingTime={printingTime}
          />
          <ManageEditSection>
            <p className='title'>Model Description</p>
            <div className='editor'>
              <QuilEditor value={value} setValue={setValue} />
            </div>
          </ManageEditSection>
        </ManageEditContainer>
      </BlurSection>
      <ManageButtonAction
        status={status}
        updateClick={updateClick}
        onClickBtn={onClickBtn}
        onClickBackBtn={onClickBackBtn}
        onClickStopSelling={onClickStopSelling}
        editValue={editModelData ? true : false}
        isAbled={isAbled}
      />
    </ManageEditContainer>
  );
};

export default ManageEdit;

const ManageEditContainer = styled.div`
  position: relative;
  margin-bottom: 83px;
`;

const BlurSection = styled.section`
  position: relative;
`;

const BlurBox = styled.div`
  background: #f5f5f58e;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1 !important;
`;
