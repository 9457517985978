import { useEffect, useState } from 'react';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import DesignerSection from './component/DesignerSection';
import Layout from '../../component/layout/Layout';
import NewDesignersJumbotron from './component/NewDesignersJumbotron';
import styled from 'styled-components';
import ToTheTop from '../Main/component/ToTheTop';
import { idsAtom, preDesignerListAtom } from '../../atom/atom';
import { useRecoilState } from 'recoil';
const NewDesigners = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const maxWidth = '1340px';
  const [, setDesignerIdList] = useRecoilState(idsAtom);
  const [preDesigner] = useRecoilState(preDesignerListAtom);
  const [showToTheTop, setShowToTheTop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function onScroll() {
    if (window.scrollY > 300) setShowToTheTop(true);
    else setShowToTheTop(false);
  }
  useEffect(() => {
    if (!preDesigner) {
      setDesignerIdList('');
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      <Layout>
        <Webpage2StylesContainer>
          <NewDesignersStyle maxWidth={maxWidth}>
            <div className='margin'>
              <h2 className='DESIGNERS'>DESIGNERS</h2>
            </div>
            <NewDesignersJumbotron isMobile={isMobile} />
            <div className='margin'>
              <DesignerSection maxWidth={maxWidth} />
            </div>
          </NewDesignersStyle>
        </Webpage2StylesContainer>
      </Layout>
      {showToTheTop && <ToTheTop />}
    </>
  );
};

export default NewDesigners;

const NewDesignersStyle = styled.div`
  overflow: hidden;
  /* overflow-y: auto; */
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }

  .DESIGNERS {
    font-size: 32px;
    font-weight: 600;
    max-width: ${props => props.maxWidth};
    margin: 0 auto;
    padding: 85px 10px 20px 0px;
  }

  .margin {
    margin: 0 10px;
  }

  @media screen and (max-width: 450px) {
    .DESIGNERS {
      font-size: 24px;
      font-weight: 600;
      font-family: 'Outfit';
      padding: 90px 0px 15px 10px;
    }

    .margin {
      margin: 0 10px;
    }
  }
`;
