import React from 'react';

const AllAccountsLogin = () => {
  return (
    <div>
      <h1>AllAccountsLogin</h1>
    </div>
  );
};

export default AllAccountsLogin;
