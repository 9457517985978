import { useState, useEffect } from 'react';
import ManageModal from '../common/ManageModal';
import {
  Button,
  ButtonWrapper,
  DraftText,
  GridItem,
  ImgWrapper,
  ProductItemContainer,
  ProductName,
} from './styles/designerItemListStyle';
import { deleteManageModel } from '../../../action/request';
import { Navigate, useNavigate } from 'react-router-dom';
import { ITEMS } from '../../../route/constant';
import { ImgStyled } from '../../../styles/ImgStyled';

const ProductItem = ({ data, setDeletedModel, isMobile, onClickEdit }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const secret = data?.status === 'secret';
  const draft = data?.status === 'draft';
  const isDraft = draft && '-';

  const handleButtonClick = async e => {
    if (e.target.textContent === 'Delete') {
      const { data: deleteModel } = await deleteManageModel(
        Number(data?.id_studio),
        Number(data?.id_item)
      );

      if (deleteModel?.returnCode !== 'C00000') {
        alert(deleteModel?.message);
      } else {
        setDeletedModel(data?.id_item);
        setModalIsOpen(false);
      }
    }
  };

  function calculateDiscountedPrice(originalPrice, discountRate) {
    if (originalPrice === 0 && discountRate === 0) {
      return '0.00';
    }

    if (originalPrice || discountRate) {
      var subTotal = (originalPrice * discountRate) / 100;
      subTotal = Math.floor(subTotal * 100) / 100;
      var finalPrice = Number(originalPrice) - subTotal;

      finalPrice = finalPrice.toString();

      const decimalIndex = finalPrice.indexOf('.');

      if (decimalIndex === -1) {
        finalPrice += '.00';
      } else {
        // 소수점이 있을 경우
        const decimalPart = finalPrice.substring(decimalIndex + 1);
        if (decimalPart.length > 2) {
          finalPrice = finalPrice.substring(0, decimalIndex + 3);
        } else if (decimalPart.length === 1) {
          finalPrice += '0';
        }
      }

      return finalPrice;
    }
  }

  const goToItemDetailPage = (e, productId, status) => {
    if (status !== 'draft') {
      e.preventDefault();
      e.stopPropagation();
      navigate(ITEMS + '/' + productId);
    }
  };

  const getPriceLabel = data => {
    if (data.original_price === null) {
      return 0;
    } else if (data?.price_option === 'paid') {
      return `$ ${calculateDiscountedPrice(data?.original_price, data?.discount_rate)}`;
    } else if (data?.price_option === 'free') {
      return 'Free';
    } else {
      return 'Reward';
    }
  };

  return (
    <>
      <ManageModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        handleButtonClick={handleButtonClick}
        modalTitle={'Are you sure?'}
        titleBold={400}
        modalContent={
          'Are you sure you want to delete this model? \n Once deleted, it cannot be recovered.'
        }
        btnContents={[
          { title: 'Cancel', backgroundColor: '#fff', color: '#000' },
          { title: 'Delete', backgroundColor: '#FD5757', color: '#fff' },
        ]}
      />
      <ProductItemContainer status={data?.status} isMobile={isMobile}>
        <GridItem className='item-id'>{data?.id_item}</GridItem>
        <GridItem
          className='img-wrapper'
          onClick={e => goToItemDetailPage(e, data?.id_item, data?.status)}
        >
          <ImgWrapper>
            {data.list_img === '' ? (
              <DraftText>{isDraft}</DraftText>
            ) : (
              <ImgStyled src={data?.list_img} alt='' />
            )}
          </ImgWrapper>
          <ProductName isDraft={isDraft}>{data?.name_en || isDraft}</ProductName>
        </GridItem>
        <GridItem className='category'>{data?.category || isDraft}</GridItem>
        <GridItem>{getPriceLabel(data)}</GridItem>
        {/* <GridItem>{item?.license_fee}</GridItem> */}
        <GridItem>{data?.downloads && Number(data?.downloads).toLocaleString()}</GridItem>

        <GridItem status={data?.status}>
          <p>{secret ? 'Not for sale' : data?.status}</p>
        </GridItem>
        {!isMobile && (
          <ButtonWrapper>
            <Button className='edit' onClick={() => onClickEdit(data.id_item)}>
              Edit
            </Button>
            {data?.status === 'draft' && (
              <Button className='delete' onClick={() => setModalIsOpen(true)}>
                Delete
              </Button>
            )}
          </ButtonWrapper>
        )}
      </ProductItemContainer>
    </>
  );
};

export default ProductItem;
