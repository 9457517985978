import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { downloadItem, welcomeUserFreeDownload, getSubDownload } from '../../action/request';
import { RETURN_CODE } from '../../action/constant';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isMobileAtom, isWelcomeModal } from '../../atom/atom';
import ProgressBar from '@ramonak/react-progress-bar';
import {
  ProcessingModalContainer,
  ModalInner,
  UlStyle,
  IconStyle,
  BarBox,
  Percent,
  Description,
  IagreeButton,
  ButtonBox,
  IconBox,
  CloseBox,
} from './styles/downloadProcessingModalStyle';

const DownloadProcessingModal = ({
  step,
  setStep,
  itemId,
  WelcomeUser,
  jobId,
  checkDownLoadStep,
  selectedName,
  onClickClose,
  onModalClose,
}) => {
  const isWelcomeModalState = useSetRecoilState(isWelcomeModal);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const interval = () => {
    const id = setInterval(() => {
      setProgress(prevProgress => {
        let newProgress = prevProgress + 2;
        if (newProgress >= 101) {
          newProgress = 0;
        }

        return newProgress;
      });
    }, 10);
    return id;
  };

  const handleClose = () => {
    onModalClose();
    setStep(0);
    setProgress(0);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setIsMobile(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 'I Agree'버튼 눌러서 다운로드
  const clickAgree = async itemId => {
    localStorage.setItem('progressModalY', true);
    setTimeout(() => {
      localStorage.removeItem('progressModalY');
    }, 500);
    if (WelcomeUser) {
      try {
        const { data: downloadRes } = await welcomeUserFreeDownload();

        if (downloadRes?.returnCode === RETURN_CODE.C00000) {
          for (const url of downloadRes.data.urls) {
            window.location.href = url;
            await new Promise(resolve => setTimeout(resolve, 2000)); // 5초 동안 대기

            isWelcomeModalState(false);
          }
        }
      } catch (e) {
        console.error('Error during download:', e);
        alert(e);
      } finally {
        //로컬에서 welcome 모달 완전 삭제
        let storedState = JSON.parse(window.localStorage.getItem('atom'));
        delete storedState.isWelcomeModal;
        window.localStorage.setItem('atom', JSON.stringify(storedState));
      }
    } else if (jobId) {
      setIsDownloading(true);

      try {
        const { data: downloadSub } = await getSubDownload(itemId, jobId);

        const url = URL.createObjectURL(downloadSub);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${selectedName}.zip`;
        link.click();
        URL.revokeObjectURL(url);
        onClickClose();
        setIsDownloading(false);
      } catch (error) {
        setIsDownloading(false);
        console.error('Download error:', error);
        alert(`An error occurred while downloading the file: ${error}`);
      }
    } else {
      try {
        const { data: downloadRes } = await downloadItem({ idItem: itemId });
        if (downloadRes?.returnCode === 'C00000') {
          // window.open(downloadRes.data.url, '_blank');
          window.location.href = downloadRes.data.url;
        } else {
          alert(downloadRes?.message);
        }
        onClickClose();
      } catch (e) {
        console.error('Error during download:', e);
        alert(e);
      }
    }
    onClickClose();
    setStep(0);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // 스크롤 막기
    return () => {
      document.body.style.overflow = 'visible'; // 스크롤 막기
    };
  }, []);

  useEffect(() => {
    if (step === 0) return;
    const id = interval();
    if (step >= 4) {
      clearInterval(id);
      setProgress(100);
    }
    return () => clearInterval(id);
  }, [step]);

  useEffect(() => {
    if (progress >= 100) {
      if (jobId) {
        if (checkDownLoadStep === 'complete') {
          if (step < 3) {
            setStep(prev => prev + 1);
          } else {
            setStep(4);
          }
        } else {
          setStep(prev => (prev + 1 > 3 ? 3 : prev + 1));
        }
      } else {
        setStep(prev => prev + 1);
      }
    }
  }, [progress]);

  return (
    <>
      {/* 다운로드 모달 */}
      {step > 0 && (
        <ProcessingModalContainer>
          <ModalInner isMobile={isMobile}>
            <CloseBox onClick={handleClose}>
              <img src='/assets/img/icon/close-button.png' alt='close' />
            </CloseBox>
            <h1>Download Processing</h1>
            <UlStyle step={step}>
              <li style={{ color: step === 1 ? '#000' : '#DFDFDF' }}>
                &gt; Generating unique identification code
              </li>
              <li style={{ color: step === 2 ? '#000' : '#DFDFDF' }}>&gt; Encrypting file</li>
              <li style={{ color: step >= 3 ? '#000' : '#DFDFDF' }}>&gt; Finishing process</li>
            </UlStyle>
            {step === 3 ? (
              <div className='processing-container'>
                <img
                  src='/assets/img/mange/processing_icon.png'
                  alt=''
                  className='processing_icon'
                />
                <span className='processing_txt'>Processing</span>
                <AnimatedDots />
              </div>
            ) : step > 3 ? (
              <div className='processing-container'>
                <p>Complete !!</p>
              </div>
            ) : (
              <BarBox>
                <ProgressBar
                  completed={progress}
                  baseBgColor='#D9D9D9'
                  bgColor='#46B81E'
                  isLabelVisible={false}
                  borderRadius='0px'
                  height='10px'
                  transitionDuration='0s'
                />
                <Percent>{progress} %</Percent>
              </BarBox>
            )}

            <IconBox>
              <IconStyle
                src='../../assets/img/notice/download_notice.png'
                alt='download notice icon'
              />
              <h2>Do not share the STL file</h2>
              <IconStyle
                src='../../assets/img/notice/download_notice.png'
                alt='download notice icon'
              />
            </IconBox>
            <Description>
              Fab365 respects the right to intellectual property. Each user is distinguished by the
              unique identification code that is encrypted when the user downloads an STL file. Any
              unauthorized sharing of downloaded STL files can lead to negative consequences.
            </Description>
            <ButtonBox>
              <IagreeButton
                disabled={step < 4 || isDownloading}
                onClick={() => {
                  clickAgree(itemId);
                }}
              >
                I Agree
              </IagreeButton>
            </ButtonBox>
          </ModalInner>
        </ProcessingModalContainer>
      )}
    </>
  );
};

export default DownloadProcessingModal;

const AnimatedDots = styled.span`
  display: inline-block;
  vertical-align: sub;
  text-align: left;
  position: relative;
  letter-spacing: 0.1em; /* 추가: content 간격 설정 */

  &::after {
    content: '';
    animation: dots 1s steps(3, start) infinite;
    position: absolute;
    bottom: 0;
    line-height: 1; /* 추가: 라인 높이 설정 */
  }

  @keyframes dots {
    0%,
    20% {
      content: '';
    }
    40% {
      content: '.';
    }
    60% {
      content: '..';
    }
    80%,
    100% {
      content: '...';
    }
  }
`;
